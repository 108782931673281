<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
              All cashflow
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <button
    class="btn btn-[#154462] mt-2"
    v-if="
      $store.getters.getUserGroup == 'superadmin' ||
      $store.getters.getUserGroup == 'premium'
    "
    @click="generatePDF()"
  >
    Generate Custom pdf
  </button>
  <div class="mt-2">
    <div class="card shadow w-100 p-4">
      <loader v-if="!data_loaded" />
      <data-table
        v-if="data_loaded"
        id="cashflowtable"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
        :on_click="dtb_on_click"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/Datatable";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "Cashflow",
  components: {
    "data-table": DataTable,
    loader: IndependentLoader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      collections: [],
      dtb_options: {
        data: [],
        // actions: [
        //   {
        //     btnText: "Delete",
        //     btnClass: "btn btn-white text-danger btn-sm",
        //     iconClass: "fas fa-trash",
        //     btnFn: (uuid) => {
        //       this.delete_collection(uuid);
        //     },
        //     btnParamName: "uuid",
        //   },
        // ],
        columns: [
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
          { title: "Reason", data: "reason" },
          { title: "Source", data: "source" },
          { title: "Action", data: "flow" },
          {
            title: "Amount",
            data: "amount",
            render: (data) => `${data["amount"]}`,
          },
        ],
        id: "true",
        extra_options: {
          buttons:
            this.$store.getters.getUserGroup == "superadmin" ||
            this.$store.getters.getUserGroup == "premium"
              ? ["copy", "csv", "print","pdfHtml5"]
              : [],
        },
      },
    };
  },
  methods: {
    generatePDF() {
      //format date

      let now = new Date();

      // extract the day, month, and year from the Date object
      let day = now.getDate().toString().padStart(2, "0"); // padStart is used to ensure the day has 2 digits
      let month = (now.getMonth() + 1).toString().padStart(2, "0"); // add 1 to the month because it is zero-indexed
      let year = now.getFullYear().toString();

      // extract the hours and minutes from the Date object
      let hours = now.getHours().toString().padStart(2, "0");
      let minutes = now.getMinutes().toString().padStart(2, "0");

      // combine the day, month, year, hours, and minutes into a single string
      let dateTimeString = `${day}/${month}/${year}_${hours}:${minutes}`;
      let doc = new jsPDF();

      // // define an array of objects to display in the table
      // let data = this.dtb_options.data

      // define the columns for your table
      let columns = ["Reason", "Source", "Amount", "Action", "DoneAt"];

      // define an array of objects to display in the table
      let data = this.dtb_options.data;

      let rows = [];
      // loop through the data and add each object to the table
      for (let i = 0; i < data.length; i++) {
        let row = [];

        row.push(data[i].reason);
        row.push(data[i].source);
        row.push(data[i].amount);
        row.push(data[i].flow);
        row.push(
          new Date(
            data[i].date_inserted.seconds * 1000 +
              Math.round(data[i].date_inserted.nanoseconds / 1000000)
          ).toLocaleDateString()
        );
        rows.push(row);
      }

      // add the table to the PDF using the autoTable plugin
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20, // set the starting y position of the table
        theme: "grid", // set the table theme to use a grid
        headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 12 }, // set styles for the table header
        bodyStyles: { textColor: 80, fontSize: 10, fontStyle: "normal" }, // set styles for the table body
        columnStyles: {
          0: { cellWidth: 50 },
          1: { cellWidth: 40 },
          2: { cellWidth: 30 },
          3: { cellWidth: 20 },
          4: { cellWidth: 30 },
        }, // set the column width of each cell
        didDrawPage: function (data) {
          // add a title to the table
          doc.setTextColor(40);
          doc.text(
            "Cashflow Report of " + dateTimeString,
            data.settings.margin.left,
            10
          );
        },
      });

      // save the PDF
      doc.save(`cashflow_of_${dateTimeString}.pdf`);
    },

    dtb_on_click: function (row) {
      var uuid = row.uuid;
      console.log(uuid);
      //  this.$router.push({ name: "Accomodation Details", params: { uuid } });
    },
    // delete_collection: function(uuid) {
    //   alert.promptDelete(this.implement_delete_collection(uuid));
    // },

    delete_collection: function (uuid) {
      const ref = doc(db, "cashflow", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_cashflows();
          alert.success_center("Success");
        })
        .catch(() => {
          alert.error("Error occured in deleting");
        });
    },

    fetch_cashflows: function () {
      var q;
      if (this.$store.getters.getUserGroup === "superadmin") {
        q = query(collection(db, "cashflow"), orderBy("date_inserted", "desc"));
      } else {
        q = query(
          collection(db, "cashflow"),
          where("user_id", "==", this.$store.getters.getUserId),
          orderBy("date_inserted", "desc")
        );
      }

      getDocs(q)
        .then((querySnapshot) => {
          this.dtb_options.data = []; // Ensure the array is cleared before populating

          if (querySnapshot.empty) {
            this.data_loaded = true;
          } else {
            let data = [];
            querySnapshot.forEach((doc) => {
              let responseData = doc.data();
              responseData.uuid = doc.id;
              data.push(responseData);
            });

            // Sort data by date_inserted in descending order (redundant if Firestore query works)
            data.sort((a, b) => b.date_inserted - a.date_inserted);

            this.dtb_options.data = data;
            this.data_loaded = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching cashflows: ", error);
          this.data_loaded = true;
        });
    },
  },
  created() {
    this.fetch_cashflows();
  },
};
</script>

<style></style>
